import styled from 'styled-components';

export const ServiceGrid = styled.ul`
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
  margin-left: -1.4285rem;
  margin-right: -1.4285rem;

  li {
    display: flex;
    width: 100%;
    padding: 0 1.4285rem 30px;
    box-sizing: border-box;

    @media (min-width: 1200px) {
      flex: 0 0 calc(100% / 2);
    }
  }
`;

export const ServiceGridThree = styled.ul`
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;

  li {
    display: flex;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;

    @media (min-width: 801px) {
      flex: 0 0 calc(100% / 2);
    }

    @media (min-width: 1024px) {
      flex: 0 0 calc(100% / 3);
    }
  }
`;

export const ServiceCard = styled.div`
  padding: 30px;
  border-radius: 2px;
  border: 1px solid var(--grey);
  transition: 0.3s cubic-bezier(0.53, 0.645, 0.51, 1.34);
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover {
    border-color: transparent;
    box-shadow: 0 3px 38px rgb(0 0 0 / 10%);
  }

  h3 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.44;
    &:after {
      content: '';
      display: block;
      margin: 10px 0;
      width: 60px;
      height: 5px;
      background: var(--dgreen);
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.643;
    margin-bottom: 20px;
  }

  .button {
    margin: auto auto 0 0;
  }
`;
