import { graphql, Link } from 'gatsby';
import React from 'react';
import SEO from '../components/SEO';
import { PageGrid } from '../styles/PageStyles';
import { ServiceCard, ServiceGridThree } from '../styles/ServiceGridStyles';

export default function HomePage({ data }) {
  const { services } = data;
  return (
    <>
      <SEO
        title="Plumbing & Heating Specialists"
        description="Emerald Heating - Suffolks plumbing and heating specialists. Call 07538008378 for a free quote on your next project."
      />
      <PageGrid>
        <div>
          <h1>Welcome to Emerald Heating</h1>
          <h3>Suffolks plumbing and heating specialists</h3>
          <p>
            Our plumbing services in Suffolk cover all types of problems from
            quick jobs such as replacing taps or repairing water leaks, to much
            larger and complicated projects such as bathroom plumbing or kitchen
            full installations.
          </p>
          <ServiceGridThree>
            {services.nodes.map((service) => (
              <li key={service.id}>
                <ServiceCard>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <Link
                    to={`/services/${service.slug.current}`}
                    className="button secondary"
                  >
                    View details
                  </Link>
                </ServiceCard>
              </li>
            ))}
          </ServiceGridThree>
        </div>
      </PageGrid>
    </>
  );
}

export const query = graphql`
  query {
    services: allSanityService {
      nodes {
        title
        id
        slug {
          current
        }
        description
        image {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
